import { template as template_42499f65cc414e0f9c5608ea0efc4af0 } from "@ember/template-compiler";
const WelcomeHeader = template_42499f65cc414e0f9c5608ea0efc4af0(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
