import { template as template_75c8d41aadc24b538be5d8fa1f3c4282 } from "@ember/template-compiler";
const FKLabel = template_75c8d41aadc24b538be5d8fa1f3c4282(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
